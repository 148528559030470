import $ from 'jquery';
window.jQuery = window.$ = $;

// Import slick carousel and its styles
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Verify slick is attached to jQuery
if (typeof $.fn.slick === 'undefined') {
    console.error('Slick not properly initialized on jQuery');
} else {
    console.log('Slick successfully initialized on jQuery');
}

// Export jQuery for other modules to use
export default $;
